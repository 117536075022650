import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { MOBILE_BREAKPOINT } from '../../constants/viewport.constants'
import { useViewport } from '../../contexts/Viewport.context'
import Button from '../Button/Button'
import Heading from '../Heading/Heading'
import Switcher, { SwitcherValue } from '../Switcher/Switcher'
import Text from '../Text/Text'
import ENTERPRISE_PRICING from './data/enterprise.data'
import PERSONAL_PRICING from './data/personal.data'

import styles from './Pricing.module.scss'

interface IPricingProps {
  pricingType?: 'personal' | 'enterprise';
}

interface IPricingListProps extends IPricingProps {
  hasMonthlyPrices?: boolean;
}

interface IPricingSwiperProps extends IPricingListProps {
  setPrices: (value: boolean) => void;
}

interface IPricingCostProps {
  price: string;
  trialPrice: string;
  term: string;
}

const PricingCost = ({ price, trialPrice, term }: IPricingCostProps) => (
  <Heading as="h4" className={styles.cost}>
    {price && (
      <Text as="span" className={classNames('heading mobile-subheading desktop-subheading', styles.price)}>
        {price}
      </Text>
    )}
    <Text as="span" className={classNames('heading mobile-h2 desktop-h2', styles.trial)}>
      {trialPrice}
    </Text>
    {term && (
      <Text as="span" className={styles.term}>
        {` / ${term}`}
      </Text>
    )}
  </Heading>
)

const PricingList = ({ pricingType, hasMonthlyPrices = false }: IPricingListProps) => (
  <div className={styles.list}>
    {Object.entries(pricingType === 'personal' ? PERSONAL_PRICING : ENTERPRISE_PRICING).map(([key, value]) => (
      <div key={key} className={classNames('d-flex', styles.item, styles[key])}>
        <Text className={classNames('text2', styles.name)}>
          {value.name}
        </Text>
        <PricingCost {...(hasMonthlyPrices ? value.monthly : value.annually)} />
        <Text className={classNames('text2', styles.text)}>
          {value.description}
        </Text>
        <div className={styles.package}>
          <Text className={classNames('text2', styles.text)}>
            Package:
          </Text>
          {value.package.map((item) => (
            <Text key={item} className={classNames('text2', styles.li)}>
              {item}
            </Text>
          ))}
        </div>
        <div className={styles.button}>
          <Button type="secondary" to="/contact" wide>
            Get started free
          </Button>
        </div>
      </div>
    ))}
  </div>
)

const PricingSwiper = ({ setPrices, pricingType, hasMonthlyPrices }: IPricingSwiperProps) => {
  const [swiperApi, setSwiperApi] = useState<SwiperClass | null>(null)

  useEffect(() => {
    if (swiperApi) {
      swiperApi.slideTo(hasMonthlyPrices ? 0 : 1)
    }
  }, [swiperApi, hasMonthlyPrices])

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    setPrices(!swiper.activeIndex)
  }, [setPrices])

  return (
    <Swiper
      onSwiper={setSwiperApi}
      onSlideChange={handleSlideChange}
      slidesPerView={1}
      className="pricing-swiper"
      spaceBetween={20}
      breakpoints={{
        [MOBILE_BREAKPOINT]: {
          spaceBetween: 36,
        },
      }}
    >
      <SwiperSlide className={classNames('d-flex', styles.card)}>
        <PricingList pricingType={pricingType} hasMonthlyPrices />
      </SwiperSlide>
      <SwiperSlide className={classNames('d-flex', styles.card)}>
        <PricingList pricingType={pricingType} />
      </SwiperSlide>
    </Swiper>
  )
}

const Pricing = ({ pricingType = 'personal' }: IPricingProps) => {
  const { isLaptop } = useViewport()
  const [hasMonthlyPrices, setPrices] = useState(true)
  const handleSwitch = useCallback((value: SwitcherValue) => setPrices(value === 'left'), [])

  return (
    <section className={classNames('d-flex-center section', styles.main)}>
      <Heading as="h2" className="heading mobile-h2 desktop-h2">
        Pricing
      </Heading>
      <div className={styles.switcher}>
        <Switcher
          left="Pay monthly"
          right="Pay yearly"
          rightBadge="20% OFF"
          onSwitch={handleSwitch}
          defaultValue={hasMonthlyPrices ? 'left' : 'right'}
        />
      </div>
      <div className={styles.swiper}>
        {isLaptop ? (
          <PricingList pricingType={pricingType} hasMonthlyPrices={hasMonthlyPrices} />
        ) : (
          <PricingSwiper
            setPrices={setPrices}
            pricingType={pricingType}
            hasMonthlyPrices={hasMonthlyPrices}
          />
        )}
      </div>
    </section>
  )
}

export default Pricing
