import classNames from 'classnames'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Heading from '../../../../components/Heading/Heading'
import Text from '../../../../components/Text/Text'
import { LAPTOP_BREAKPOINT, MOBILE_BREAKPOINT } from '../../../../constants/viewport.constants'
import { ReactComponent as AlertsSvg } from './assets/alerts.svg'
import { ReactComponent as ApiSvg } from './assets/api.svg'
import { ReactComponent as DataSvg } from './assets/data.svg'

import styles from './Features.module.scss'

const FEATURES = {
  alerts: {
    SvgComponent: AlertsSvg,
    title: 'Real-time threat alerts',
    subtitle: 'Stay ahead of fraudsters and minimize risk',
    description: 'Receive instant notifications of emerging threats to protect your business',
  },
  api: {
    SvgComponent: ApiSvg,
    title: 'Customizable API integration',
    subtitle: 'Enhance your existing security infrastructure without disrupting operations',
    description: 'Seamlessly integrate our API into your existing systems for a smooth workflow',
  },
  data: {
    SvgComponent: DataSvg,
    title: 'Comprehensive data analysis',
    subtitle: 'Identify vulnerabilities and optimize your fraud prevention strategies',
    description: 'Gain valuable insights into fraud patterns and trends',
  },
}

const FeatureSlides = () => (
  <Swiper
    autoplay={{
      delay: 10000,
    }}
    slidesPerView="auto"
    className="enterprise-swiper"
    spaceBetween={16}
    modules={[Autoplay]}
    breakpoints={{
      [MOBILE_BREAKPOINT]: {
        spaceBetween: 20,
      },
      [LAPTOP_BREAKPOINT]: {
        slidesPerView: 3,
      },
    }}
  >
    {Object.entries(FEATURES).map(([key, value]) => (
      <SwiperSlide key={key} className={classNames('d-flex', styles.card)}>
        <value.SvgComponent className={styles.icon} />
        <Text className="heading mobile-subheading desktop-subheading">
          {value.title}
        </Text>
        <Text className="text2">
          {value.subtitle}
        </Text>
        <Text className={classNames('text3', styles.description)}>
          {value.description}
        </Text>
      </SwiperSlide>
    ))}
  </Swiper>
)

const Features = () => (
  <section className={classNames('section', styles.main)}>
    <Heading as="h2" className={classNames('heading mobile-h2 desktop-h2 narrow-content', styles.heading)}>
      A new way of establishing secure environment
    </Heading>
    <FeatureSlides />
  </section>
)

export default Features
